@import "../../../../styles/scss/abstracts/variables";

.close {
    float: right;
    font-size: 21px;
    line-height: 1;
    color: $Black;
    opacity: .2;
    background: transparent;
    border: 0;
}
.btn-primary {
    background: $red;
    border-width: 0;
    box-shadow: none;
    border-color: $red;
    color: $white;
    &:hover {
        background: $neutral-grey-8;
        color: $white;
    }
}