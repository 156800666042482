@import "../../../styles/scss/abstracts/_variables.scss";

.modal-text-small {
  font-size: 12px;
  padding: 5px;
  line-height: 18px;
  letter-spacing: 1px;
}
.model-btn {
  text-align: center;
  padding-bottom: 20px;
}
.login_google {
  color: $red;
  padding: 15px 30px 15px 30px;
  background-color: transparent;
  border: 2px solid $red;
  &:hover {
    transition: all 0.3s;
    background: $red;
    color: $white;
  }
}
