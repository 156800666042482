@import "../../../styles/scss/abstracts/variables";

.about-wrapper {
  background: $red;
}
.about-title {
  padding: 40px 0px;
  font-family: "bentonsansmedium";
  color: $white;
}
.tickets a {
  text-decoration: none;
}
.radio{
  width: 100%;
  margin-bottom: 15px !important;
}
.radio [type="radio"]:checked,
.radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio [type="radio"]:checked + label,
.radio [type="radio"]:not(:checked) + label{
  position: relative;
  padding-left: 30px;
  padding-right: 10px; 
  cursor: pointer;
  color: #282C35;
font-family: 'bentonsansmedium';
  font-size: 16px;
}
.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before,
.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after {
  border-radius: 50%;
}
.radio [type="radio"]:checked + label:before,
.radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left:0;
  top:0px;
  width: 20px;
  height: 20px;
  border: 1px solid $red;
  background: $white;
}
.radio [type="radio"]:checked + label:after,
.radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 14px;
  height: 14px;
  background: $red;
  position: absolute;
  top:3px;
  left:3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
} 
.add_tickets {
  display: inline-block;
  text-decoration: none;
  text-align: center !important;
}
.imagename{
  margin-left:5px;
  position: relative;
  top: 40%;
}
.icon-close {
  height: 18px;
    width: 18px;
    color: $red;
    cursor: pointer;
    background: rgb(224, 191, 195);
    border-radius: 10%;
}
.button{
  .btn {
    border: unset;
  }
.btn-primary {
  background: $white;
  border-color: $white;
  color: $Black;
}
.btn-primary:hover {
  background: $white;
  color: $Black;
}
.btn:active {
  background: $white;
  border-color: $white;
  color: $Black;
}
.btn:hover {
  border-color: $white;
}
.btn:active:focus {
  box-shadow: unset;
}
}
.divider {
  border-left: 1px solid #ccc;
}

