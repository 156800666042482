.event-img {
  min-height: 750px;
  max-width: 800px;
  object-fit: cover;
  border-radius: 30px;
}

.event-status-div {
  font-weight: 600;
  font-family: "Montserrat";
  .event-status-heading {
    color: white;
    padding: 8px;
    background: #00000099;
    border: 0.5px solid black;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
  }
  .event-status-value {
    color: black;
    background: #fff0e5;
    padding: 8px;
    font-weight: 600;
    border-radius: 0px 5px 5px 0px;
    border: 0.5px solid black;
  }
}


@media screen and (min-width: 575px) and (max-width: 991px) {
  .event-img {
    min-height: 500px;
    max-width: 800px;
    object-fit: cover;
    border-radius: 30px;
  }
  
}

@media screen and (min-width: 200px) and (max-width: 574px) {
  .event-img {
    min-height: 400px;
    max-width: 800px;
    object-fit: cover;
    border-radius: 30px;
  }

}