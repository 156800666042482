.kundli-input-div {
  input {
    height: 74px !important;
  }
  select {
    height: 74px !important;
  }
  button {
    height: 74px !important;
  }
}

.kundli-gradient-1 {
  background: linear-gradient(
      to right,
      rgba(0, 255, 26, 0.8),
      rgba(155, 118, 255, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}
.kundli-gradient-2 {
  background: linear-gradient(
      to right,
      rgba(100, 202, 153, 0.8),
      rgba(255, 0, 106, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}
.kundli-gradient-3 {
  background: linear-gradient(
      to right,
      rgba(96, 151, 203, 0.8),
      rgba(255, 170, 0, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}
.kundli-gradient-4 {
  background: linear-gradient(
      to right,
      rgba(54, 122, 255, 0.8),
      rgba(0, 255, 102, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}
.kundli-gradient-5 {
  background: linear-gradient(
      to right,
      rgba(255, 0, 106, 0.8),
      rgba(100, 202, 153, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}
.kundli-gradient-6 {
  background: linear-gradient(
      to right,
      rgba(255, 170, 0, 0.8),
      rgba(96, 151, 203, 0.8)
    ),
    url("../../img/kundli/detail-bg-img-left.png") top left,
    url("../../img/kundli/detail-bg-img-right.png") bottom right;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .kundli-input-div {
    input {
      height: 40px !important;
    }
    .form-select {
      height: 40px !important;
      padding-top: 14px;
      padding-bottom: 0;
    }
    label {
      font-size: 10px;
      margin-bottom: 2px;
    }
    button {
      height: 40px !important;
    }
  }
  .label {
    font-size: 10px !important;
  }
  .details-body {
    .list-group-item {
      padding: 0 0 0 5px !important;
    }

    thead {
      tr {
        th {
          padding: 5px;
          font-size: 2vw;
        }
      }
    }
    tbody {
      td {
        padding: 5px;
      }
    }
  }
}
