.panchang-details-container {
  border-radius: 1.46vw;
  padding: 2.92vw;
  margin-bottom: 4.98vw;
}
.arrow-button {
  width: 4.4vw;
  height: 4.4vw;
  border: 1px solid black;
  border-radius: 0.512vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.today-button {
  color: white;
  background-color: #e28e00;
  border: 0;
  border-radius: 0.512vw;
  width: 11.71vw;
  height: 4.4vw;
  font-weight: 600;
}
.select-date {
  height: 4.4vw;
  width: 25.62vw;
  padding: 0 1.4vw;
  background: white;
  border: 1px solid black;
  border-radius: 0.512vw;
  display: flex;
  align-items: center;
}
.date-picker {
  width: 18.3vw;
  font-size: 1.9vw;
  font-family: "montserrat";
  border: 0;
}

.panchang-basic-details-div {
  width: 16.84vw;
  min-height: 8.27vw;
  img {
    height: 8.27vw;
    width: 8.27vw;
  }
  .panchang-basic-detail-name {
    font-size: 1.83vw;
    font-family: "montserrat";
    font-weight: 800;
  }
  .panchang-basic-detail-value {
    font-size: 1.46vw;
    font-weight: 700;
    font-family: "montserrat";
    color: #828282;
  }
}
.details-header {
  min-height: 6.8vw;
  font-size: 3.3vw;
  font-family: "Playfair Display", serif;
  color: #e28e00;
  background: #483f50;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.46vw 1.46vw 0 0;
}
.details-body {
  padding: 1.46vw;
  border-radius: 0 0 1.46vw 1.46vw;
  // margin-bottom: 68px;
  margin-bottom: 5.27vw;
}
.panchang-gradient-1 {
  background: linear-gradient(
      to right,
      rgba(0, 255, 26, 0.8),
      rgba(155, 118, 255, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}
.panchang-gradient-2 {
  background: linear-gradient(
      to right,
      rgba(100, 202, 153, 0.8),
      rgba(255, 0, 106, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}
.panchang-gradient-3 {
  background: linear-gradient(
      to right,
      rgba(96, 151, 203, 0.8),
      rgba(255, 170, 0, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}
.panchang-gradient-4 {
  background: linear-gradient(
      to right,
      rgba(54, 122, 255, 0.8),
      rgba(0, 255, 102, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}
.panchang-gradient-5 {
  background: linear-gradient(
      to right,
      rgba(255, 0, 106, 0.8),
      rgba(100, 202, 153, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}
.panchang-gradient-6 {
  background: linear-gradient(
      to right,
      rgba(255, 170, 0, 0.8),
      rgba(96, 151, 203, 0.8)
    ),
    url("../../img/panchang/detail-bg-img-left.png") left,
    url("../../img/panchang/detail-bg-img-right.png") right;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .panchang-basic-details-div {
    img {
      height: 8.27vw;
      width: 8.27vw;
    }
    .panchang-basic-detail-name {
      font-size: 2.2vw;
      font-family: "montserrat";
      font-weight: 800;
    }
    .panchang-basic-detail-value {
      font-size: 2vw;
      font-weight: 700;
      font-family: "montserrat";
      color: #828282;
    }
  }
  .arrow-button {
    width: 6vw;
    height: 6vw;
  }
  .today-button {
    width: 11.71vw;
    height: 6vw;
  }
  .select-date {
    height: 6vw;
    width: 25.62vw;
    padding: 0 1.4vw;
    background: white;
    border: 1px solid black;
    border-radius: 0.512vw;
    display: flex;
    align-items: center;
  }
  .date-picker {
    width: 18.3vw;
    font-size: 1.9vw;
    font-family: "montserrat";
    border: 0;
    margin-bottom: 8px;
  }
}
