.article-card-button {
  border: 0;
  background-color: transparent;
  }

.article-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #f16334;
  border-radius: 5px;
  height: 100%;
  max-height: 250px;
  background-color: transparent;
}

.article-card-img {
  //   width: 100%;
  // min-height: 300px;
  //   max-height: 300px;
  //   object-fit: fill;
  //   padding: 5px;
  //   border-radius: 8px 8px 0 0;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.article-title-div {
  width: 100%;
  min-height: 50px;
  display: flex;
}

.article-title {
  color: white;
  text-align: center;
  margin: auto;
  font-weight: 700;
}

@media screen and (min-width: 992px) and (max-width: 1274px) {
  .article-title-div {
    height: 8vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .article-title-div {
    height: 8vh;
  }
  .article-title {
    font-size: 14px;
  }
  .article-card-img {
    min-height: 200px;
    max-height: 200px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .article-title-div {
    height: 8vh;
  }
  .article-title {
    font-size: 12px;
  }
  .article-card-img {
    min-height: 150px;
    max-height: 150px;
  }
}

@media screen and (min-width: 382px) and (max-width: 575px) {
  .article-title-div {
    height: 8vh;
  }
  .article-title {
    font-size: 12px;
  }
  .article-card-img {
    min-height: 150px;
    max-height: 150px;
  }
}

@media screen and (max-width: 381px) {
  .article-title-div {
    height: 8vh;
  }
  .article-title {
    font-size: 10px;
  }
  .article-card-img {
    min-height: 120px;
    max-height: 120px;
  }
}
