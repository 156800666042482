.pac-container {
  z-index: 99999;
  border-top: none;
  font-family: unset;
  box-shadow: none;
  .pac-item:first-child {
    border-top: none;
  }
  .pac-item:hover {
    background-color: #ebf2fe;
  }
}
