@import "../../styles/scss/abstracts/variables";

// .box {
//   background: $white;
//   box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
//   border-radius: none !important;
//   height: auto;
// }
// .content-box {
//   font-size: 14px;
//   padding-left: 10px;
//   margin-top: 10px;
// }

// .content-link {
//   color: $red;
//   font-weight: bold;
//   text-decoration: none;
//   &:hover {
//     color: $neutral-grey-3;
//   }
// }
// .price {
//   background: $red;
//   bottom: 10px;
//   left: 10px;
//   height: 35px;
//   border-radius: 5px 5px 5px 5px;
//   text-align: center;
//   padding: 5px 10px;
//   color: $white;
//   border: none;
// }

// .share-icon {
//   width: 15%;
//   text-align: center;
//   padding: 0px 10px;
//   border-left: 0px solid $platinum;
// }
// .pull-right {
//   float: right;
// }
// .icon {
//   color: $red;
//   height: 30px;
//   width: 30px;
// }

.event-card {
  min-height: 390px;
  border: 0;
  border-radius: 25px;
  font-family: "Montserrat";
  position: relative;
  width: 100% !important;
  //background-color: #fff0e5;

  @media (min-width: 576px) {
    width: 550px; // Adjust this value as needed
  }

  img {
    height: 242px;
    min-height: 100%;
    object-fit: cover;
    border-radius: 25px 25px 0 0;
  }
  h5 {
    font-family: "Playfair Display";
    font-size: 16px;
    color: #ff6800;
    // max-width: 150px;
    word-wrap: break-word;
  }
  .event-type {
    height: 26px;
    font-size: 13px;
    font-weight: 700;
    padding: 6px 9px;
    border-radius: 3px;
  }

  .card-body {
    flex-grow: 1;
    min-height: 200px;
    display: flex;
    flex-direction: column;
  }

  .status-div {
    min-height: 22px;
    max-width: 140px;
    font-size: 10px;
    font-weight: 600;
    font-family: "Montserrat";
    border: 0.5px solid #000000;
    border-radius: 3px;

    .status-heading {
      color: white;
      max-width: 40px;
      background: #00000099;
      border-radius: 2.5px 0px 0px 2.5px;
    }
    .status-value {
      background: #fff0e5;
      border-radius: 0px 2.5px 2.5px 0px;
      text-transform: capitalize;
    }
  }
  .event-time {
    font-size: 15px;
    font-weight: 600;
  }
  .event-address {
    font-size: 15px;
    font-weight: 600;
  }
}
.event-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card {
  @media (max-width: 576px) {
    width: 100%;
    margin-right: 0; /* Remove any right margin if present */
    margin-left: 0; /* Remove any left margin if present */
  }
}
