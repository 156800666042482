.category-card-button {
  border: 0;
  background-color: transparent;
  margin-bottom: 10px;
}

.category-card {
  width: 100%;
  border: 2px solid #f16334;
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
  flex-direction: column;
}

.category-card-img {
  flex-grow: 1;
  width: 100%;
  max-height: 150px;
  //max-height: 170px;
  object-fit: cover;
  display: block;

  border-radius: 8px 8px 0 0;
  padding: 5px;
}

.category-title-div {
  width: 100%;
  display: flex;
  align-items: center;
}

.category-title {
  color: white;
  text-align: center;
  margin: auto;
  font-weight: 700;
  font-size: 16px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .category-title {
    font-size: 17px;
  }
}

@media screen and (min-width: 419px) and (max-width: 575px) {
  .category-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 418px) {
  .category-title {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .row.g-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .category-card-button {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 10px;
  }

  .category-card-img {
    width: 100%;
    height: 95px;
    object-fit: cover;
  }
}
