@import "../../styles/scss/abstracts/_variables.scss";

.sidenavdraw {
  height: -webkit-calc(103vh - 0%);
  height: calc(103vh - 0%);
  position: absolute;
  z-index: 1024;
  top: 70px;
  background-color: $white;
  overflow: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  right: 0;
}

.location-side {
  position: absolute;
  left: 22px;
  z-index: 12;
  top: 17px;
  svg {
    height: 22px;
    width: 22px;
    color: $red;
  }
}
.selectbox__menu {
  z-index: 43 !important;
}

.showdrawer {
  max-width: 18rem !important;
  width: auto;
  z-index: 99999;
}

.hidedrawer {
  width: 0px !important;
  right: -200px;
  transition: width 0.4s ease-out;
}
// .actionside {
//   position: absolute;
//   top: 5px;
//   right: 0;
//   .show.dropdown {
//     .dropdown-toggle::after {
//       transform: rotate(-45deg);
//       top: 7px;
//     }
//   }
//   .dropdown {
//     box-shadow: none;
//   }
//   .dropdown-toggle {
//     background-color: unset;
//     color: $white;
//     border: unset;
//   }
//   .dropdown-toggle::after {
//     color: $Black;
//     position: relative;
//     top: 1px;
//     left: 0px;
//     content: "";
//     display: inline-block;
//     width: 9px;
//     height: 9px;
//     border-right: 2px solid #666666;
//     border-top: 2px solid #666666;
//     transform: rotate(135deg);
//     margin-right: 0.5em;
//     margin-left: 1em;
//     border-left: unset;
//   }
//   .dropdown-menu {
//     border-radius: unset;
//     padding: 0 !important;
//     transform: translate(-4px, 42px) !important;
//     min-width: 76px !important;
//     .dropdown-item:hover {
//       color: $white !important;
//       background-color: $red !important;
//     }
//   }
//   .btn:active:focus {
//     box-shadow: none;
//   }
// }
.detail-info {
  font-size: 12px;
  font-weight: 500;
  color: $Black;
  margin-top: 12px;
  span {
    font-size: 12px;
    margin-left: 11px;
  }
}

.ver {
  right: 50px !important;
}
