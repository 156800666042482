@import "../../../styles/scss/abstracts/variables";

.save-body{
    padding: 10px;
}
.modal-text {
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    padding: 10px 15%;
    line-height: 22px;
    font-weight: 400;
}
.form-textbox {
    border-radius: 0px;
    padding: 12px;
}
.btn-payment {
    color: $red;
    padding: 10px 30px;
    background-color: transparent;
    border: 2px solid $red;
    &:hover {
        transition: all 0.5s;
        background: $red;
        color: $white;
    }
}
.input {
    display: flex;
    justify-content: center;
}
.model-btn {
    text-align: center;
    padding-bottom: 20px;
}
.modal-title {
    text-align: left !important;
    margin-left: 22px;
    margin-top:22px;
    font-size: 19px !important;
}
.card-mobile {
    height: auto;
    padding: 12px;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: $white;
  }
.grid-container-div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }
  .padding {
    padding: 10px 10px 10px 10px;
  }

  @media (min-width: 401px) and (max-width: 1068px){
    .grid-container {
        grid-template-columns: auto auto;
      }
  }
  
  @media (max-width: 400px) {
    .grid-container {
        grid-template-columns: auto;
      }
  }

