@import "../styles/scss/app.scss";
@import "../styles/scss/abstracts/_variables.scss";
@import "react-datepicker/dist/react-datepicker.css";
@import "sweetalert2/src/sweetalert2.scss";
@import "react-toastify/dist/ReactToastify.css";

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
// @media (min-width: 992px){
// .container {
//     max-width: 960px;
// }
// }
// @media (min-width: 768px){
// .container {
//     max-width: 720px;
// }
// }
// @media (min-width: 576px){
// .container {
//     max-width: 540px;
// }
// }
.app {
  font-family: "bentonsansregular", "Helvetica Neue", Helvetica, Roboto, Arial,
    sans-serif;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.page-main-contain {
  padding-top: 40px;
  position: relative;
  min-height: 451px;
}
.icons {
  width: 30px;
  height: 30px;
  line-height: 40px;
  color: $red;
  &:hover {
    color: $neutral-grey-5;
  }
}
.modal-title {
  text-align: center;
  margin-top: 30px;
  font-size: 26px;
  font-weight: 600;
}
.close {
  right: 15px;
  top: 10px;
  position: absolute;
  z-index: 1;
}
.error {
  color: red;
  float: left;
}
.notFound {
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  color: #282c35;
  text-align: center;
  margin-top: 65px;
  padding-bottom: 75px;
}
.nodata {
  display: flex;
  justify-content: center;
}
.nodatawrapper {
      background: #f8f8fa;
      border: 1px solid #efefef;
      padding: 8px;
      margin-bottom: 20px;
      margin-top: 20px;
      position: relative;

}
.noDataContent {
  color: #F16334;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0 0 0 0;
  width: calc(100% - 75px);
  display: flex;
  justify-content: center;
}

.select__menu-list {
  max-height: 167px !important;
}
// .detail-box-wrapper {
//     box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
//     margin-top: 100px;
// }
// .cover-wrapper-details {
//     background: #fff;
//     padding: 0px;
// }
