@import "../../styles/scss/abstracts/_variables.scss";

.list-bg {
  background: linear-gradient(0deg, rgba(241, 99, 52, 0.8), rgba(0, 0, 0, 0.2)),
    url(../../../public/img/event-list-bg.jpg);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 480px;
  position: absolute;
  top: 65px;
  z-index: -1;
}
.list-widget {
  margin-top: 245px;
  background: $white;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%) !important;
  margin-bottom: 50px;
}
#create-event-box {
  position: relative;
}
.create-event-button {
  position: absolute;
  top: 20px;
  right: 50px;
  width: 15%;
  height: 25%;
  background-color: #f16334;
  color: white;
  // border: 0;
  // border-radius: 5px;
}

.events-title {
  font-size: 35px;
  margin-top: 20px;
  font-weight: 700 !important;
}
.map-under-box {
  padding-top: 10px;
}
.cat-menu-map {
  position: absolute;
  min-width: 315px;
  background: $white;
  z-index: 1;
  /* margin-top: 20px; */
  padding: 0px;
  list-style: none;
  margin-bottom: 20px;
}
.cat-menu-map-a {
  text-decoration: none;
  display: block;
  padding: 5px 0px;
  color: $white !important;
}
.parent-cate-design {
  padding: 0 10px;
  background-color: $red;
}
.down-icon {
  font-size: 25px !important;
  color: $white;
  float: right;
  width: 16px;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.not-found-events {
  border: 1px solid $wisp-pink;
  padding: 8px;
  width: 180px;
}

.event-type-button {
  min-height: 55px;
  min-width: 230px;
  font-weight: 700;
  border-radius: 5px;
}
.event-type-clicked {
  opacity: 100%;
  border: 2px solid black;
}
.event-type-not-clicked {
  opacity: 50%;
  border: 0;
}
