@import "../../styles/scss/abstracts/_variables.scss" ;
    
.homepage-slider {
    display: table;
    height: 300px;
    position: relative;
    width: 100%;
}
.slider_img {
    height: 300px;
    position: absolute;
    background: 50%/cover;
    width: 100%;
}
.slider_content {
    /* display: table-cell; */
    padding: 0 20px;
    text-align: center;
    /* vertical-align: middle; */
    /* z-index: 1; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.slider_title {
    font-size: 34px;
    line-height: 46px;
    color: $white;
    padding-bottom: 15px;
    max-width: 650px;
    margin: 12px auto 0 auto;
}