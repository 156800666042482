@import "../../../styles/scss/abstracts/_variables.scss";

.text-capitalize {
  text-transform: capitalize !important;
}
.text-right {
  text-align: right !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-danger {
  color: $red-1 !important;
}
.form-control {
  &:focus {
    box-shadow: none !important;
    border-color: #f16334;
  }
}
