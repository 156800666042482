@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.route-heading {
  font-size: 2.45vw;
  font-family: "Playfair Display", serif;
  margin-bottom: 1.5vw;
  text-align: center;
}
.montserrat-font {
  font-size: 1.75vw;
  /* font-size: 25px; */
  font-family: "montserrat";
  font-weight: 450;
}
.poppins-font {
  font-size: 3.66vw;
  font-family: "poppins";
  font-weight: 700;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #eb7625;
  border-radius: 5px;
}
@media screen and (max-width: 600px) {
  .route-heading {
    font-size: 7vw;
    margin-bottom: 4.5vw;
  }
  .montserrat-font {
    font-size: 2.5vw;
  }
  .poppins-font {
    font-size: 15px;
  }
}

.drop-down{
  padding:30px;
}

.carousel .thumbs-wrapper {
  margin-top: 10px; 
}
.custom-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.custom-scroll::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.custom-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* WebKit */
}

.carousel .control-dots .dot {
  background-color: #79797B !important;
}

.carousel .control-dots {
  margin-bottom: 10px !important; 
}

.carousel .control-dots .dot.selected {
  background-color: #FFA900 !important;
}

.form_wrapper {
  select {
    width: 100%;
  }
}

.zodiac-image {
  width: 60px;   
  height: 60px;
}

.shop{
  width: 100px;   
  height: 40px;
}

.carousel .control-dots {
  margin-bottom: 0px !important; 
}


@media (min-width: 768px) { 
  .zodiac-image {
    width: 89px;
    height: 89px;
  }
  .shop{
    width: 168px;   
    height: 56px;
  }
  .carousel .control-dots {
    margin-bottom: 40px !important; 
  }
}



