.horoscope-period {
  box-shadow: 1px 4px 8px 2px rgba(0, 4, 19, 0.4);
  background-color: #eb7625;
  border: 1px solid #eb7625;
  font-size: 0;
  width: 16.62vw;
  height: 12.23vw;
}
.not-horoscope-period {
  background-color: white;
  border: 1px solid black;
  font-size: 0;
  width: 16.62vw;
  height: 12.23vw;
}
.horoscope-sign-clicked {
  background: linear-gradient(#ff6129, #00b876, #ffa900, #4f5bff);
  border-radius: 50%;
  height: 13.18vw;
  width: 13.18vw;
  opacity: 100%;
}
.horoscope-sign-not-clicked {
  background: white;
  border-radius: 50%;
  height: 13.18vw;
  width: 13.18vw;
  opacity: 40%;
}
.horoscope-details h6 {
  font-size: 3.29vw;
  font-weight: 700;
}
.horoscope-details p {
  font-size: 1.83vw;
}
.bg-1 {
  background: linear-gradient(#ffbfa8, #fd855a);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
.bg-2 {
  background: linear-gradient(#3affb8, #00b876);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
.bg-3 {
  background: linear-gradient(#ffde9c, #ffc045);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
.bg-4 {
  background: linear-gradient(#c4c8ff, #8b93ff);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
.bg-5 {
  background: linear-gradient(#dfd1c5, #aca59e);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
.bg-6 {
  background: linear-gradient(#ffd382, #ffa500);
  border-radius: 1.46vw;
  padding: 2.2vw;
}
