@import "../../styles/scss/abstracts/_variables.scss";

.footer-wrapper {
  padding: 30px 0 0 0;
  background: $platinum;
}
#mobile-frame-box {
  position: relative;
  width: 90%;
  margin: auto;
}

.mobile-frame {
  max-width: 100%;
  height: auto;
}

#img-frame-1 {
  position: absolute;
  top: 80px;
  left: 50px;
}

@media (max-width: 640px) {
  .social-icon {
    padding: 6px 10px;
    text-align: left !important;
  }
}
.footer {
  padding-top: 100px;
  width: 100%;
  background: #483f50;
  color: #fff;
  font-family: "montserrat";
  border-top: 3px solid;
  border-top-color: #eb7625;
}

.footer-title {
  position: relative;
  padding-bottom: 20px;
  color: #eb7625;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  border-bottom: 1px solid #4e4e4e;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    background: #eb7625;
  }
}

.footer-links a {
  font-family: "montserrat";
  font-size: 14px;
  padding: 5px 0;
  border-bottom: 1px solid #404040;
  color: white;
  display: block;
  transition: color 0.5s ease-in-out;
  text-decoration: none;
  &:hover {
    color: #eb7625;
  }
}

.footer-social-links a {
  width: 40px;
  height: 40px;
  font-size: 18px;
  float: left;
  border: 1px solid #eb7625;
  border-radius: 50%;
  line-height: 1;
  transition: background 0.3s ease-in-out;
  background: #483f50;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb7625;
  &:hover {
    background: #eb7625;
    color: #483f50;
  }
}
.copyright-div {
  width: 100%;
  padding-top: 50px;
  text-align: center;
  color: #bdbdbd;
  font-size: 20px;
}
