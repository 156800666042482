@media (max-width: 767px) {
  .item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    width: 30%;
    margin: 5px;
  }

  .cat-title {
    font-size: 10px;
  }

  .item-name {
    font-size: 14px;
  }
}

// .item-container {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }

// .card {
//   width: 45%;
//   margin: 10px;
// }

// @media (max-width: 767px) {
//   .card {
//     width: 90%;
//     margin: 5px;
//   }
// }
