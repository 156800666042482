@import "../../styles/scss/abstracts/_variables.scss" ;

.social-logo-detail {
    display: inline-block;
    margin-right: 10px;
    span {
        background: $neutral-grey-2;
        height: 45px;
        width: 45px;
        display: inline-block;
        border-radius: 50%;
        font-size: 22px;
        text-align: center;
        color: $white;
        display: flex;
        justify-content: center;
        margin: 0;
    }
}