.kundli-button {
  min-height: 5vw;
  max-width: 20vw;
  padding: 0.85vw;
  border: 0;
  border-radius: 0.732vw;
  background: #ffffff99;
}
.kundli-button-clicked {
  color: white;
  background: #e28e00;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.23);
}
.kundli-button-not-clicked {
  color: #00000099;
  background: #ffffff99;
}
