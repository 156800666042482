@import "../../styles/scss/abstracts/_variables.scss";

.userprofile {
  .list-bg {
    background: linear-gradient(0deg,
        rgba(241, 99, 52, 0.8),
        rgba(0, 0, 0, 0.2)),
      url(../../../public/img/event-list-bg.jpg);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 480px;
    position: absolute;
    top: 65px;
    z-index: -1;
  }

.info-container{
  padding-left: 120px;
  padding-right: 120px;
  width: 90%;
  margin: auto;
}

  .profile-img {
    width: 120px;
    height: 120px;
  }

  .list-widget {
    margin-top: 245px;
    background: $white;
    box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%) !important;
    margin-bottom: 50px;
  }

  .events-title {
    font-size: 35px;
    margin-top: 20px;
    font-weight: 700 !important;
  }

  .map-under-box {
    padding-top: 10px;
  }

  .cat-menu-map {
    position: absolute;
    min-width: 315px;
    background: $white;
    z-index: 1;
    padding: 0px;
    list-style: none;
    margin-bottom: 20px;
  }

  .cat-menu-map-a {
    text-decoration: none;
    display: block;
    padding: 5px 0px;
    color: $white !important;
  }

  .parent-cate-design {
    padding: 0 10px;
    background-color: $red;
  }

  .down-icon {
    font-size: 25px !important;
    color: $white;
    float: right;
    width: 16px;
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .mt-1 {
    margin-left: 2px;
  }

  .info {
    font-size: 15px;
    font-weight: 500;
    color: $neutral-grey-8;
    margin-top: 12px;

    span {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .followers {
    color: $Black;

    span {
      margin-left: 5px;
      color: $neutral-grey-8;
    }

    .btn_followes {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .dropdown-item-design {
    .nav-tabs {
      border-bottom: unset !important;
      border-top: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
    }

    .nav-item:hover {
      border-bottom: unset;
    }

    .nav-link {
      color: $neutral-grey-8;
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-radius: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      font-size: 14px;
      font-weight: 500;
    }

    .nav-link:hover {
      border-color: $white;
    }

    .nav-link.active {
      color: rgb(103, 97, 97);
      border-top: 2px solid rgb(103, 97, 97);
    }
  }

  .follow-right {
    position: absolute;
    right: 25px;
  }

  .followers {
    font-size: 15px;
    font-weight: 500;
    color: $neutral-grey-8;
  }

  .text-aling-right {
    text-align: right !important;
  }

  .btn-primary:hover {
    background: $red;
    color: $white;
  }

  @media (min-width: 413px) and (max-width: 1202px) {
    .nav-tabs {
      display: block;
    }
  }

  @media (min-width: 350px) and (max-width: 1019px) {
    .nav-tabs {
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    
    .info{
      text-align: center;
    }
    .info-container{
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      margin-top: 200px;
      overflow: hidden;
      overflow-x: hidden;
    }
  }
}