@import "../../../styles/scss/abstracts/_variables.scss";

.email-account-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: $neutral-grey-9;
  margin-bottom: 5px;
}
.email-contain {
  font-size: 15px;
}
.label-text {
  font-size: 14px;
  color: $neutral-grey-9;
  letter-spacing: 0.5px;
}
.form-textbox {
  border-radius: 0px;
  padding: 12px;
  margin-top: 8px;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: $white;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.contect-form {
  padding-top: 10px;
}
.user-img {
  height: 100px;
  width: 100px;
}
.select__control {
  padding: 6px;
}

.select__control--is-focused {
  border-color: $red !important;
  box-shadow: none !important;
}
.img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 8px;
  background: $platinum;
  color: $Black;
  text-align: center;
  cursor: pointer;
  font: 18px Arial, sans-serif;
  position: relative;
  right: 26px;
  top: 30px;
}
