@import "../../../styles/scss/abstracts/_variables.scss";

.pro-choose-file {
  margin-top: 10px;
  width: 100%;
  padding: 8px;
  color: $red;
  background-color: transparent;
  border: 2px solid $red;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
  &:hover {
    transition: all 0.3s;
    background: $red;
    color: $white;
  }
}

.follow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(13, 110, 253, 0.9490196078);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 8px 16px;
  &:hover {
    color: white;
  }
}
