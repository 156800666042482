@import "../../styles/scss/abstracts/_variables.scss";

.suggested {
  .follow-s {
    background: $ligh-sky-blue;
    border: none;
    border-radius: 5px;
    color: $white;
    padding: 10px;
    width: 100px;
    margin-top: 12px;
  }
  .btn-primary:hover {
    background: $ligh-sky-blue;
    color: $white;
  }
  .btn:active:focus {
    box-shadow: none !important;
  }
  .react-multi-carousel-item {
    width: 13rem !important;
  }
  .card {
    border: unset;
  }
  .react-multi-carousel-list {
    position: unset !important;
  }
}
