@import "../../styles/scss/abstracts/_variables.scss";

.profile-title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 50px;
  color: $neutral-grey-9;
  text-rendering: optimizelegibility;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.collapse-menus {
  display: grid;
  list-style: none;
  background: $white;
}
.profile-nav{
  .nav-item {
    border-bottom: 1px solid $pale_blue;
    padding: 0px;
  }
  .nav-link {
    color: $red !important;
    padding: 15px 15px;
    font-size: 17px !important;
    font-weight: bolder;
    transform: translateY(0%);
    border: 1px solid $platinum;
    display: block;
    border: none !important;
    background-color: unset !important;
    width: 100%;
    text-align: left;
    border-radius: unset !important;
  }
  .nav-tabs {
    border: 1px solid $pale_blue;
  }
  .nav-link.active {
    background-color: $red !important;
    color: $white !important;
    z-index: 999 !important;
  }
}
.tab-content > .active {
  opacity: 1;
}
.profile-title-text {
  padding-bottom: 2px;
  border-bottom: 1px solid $light-grey;
  margin-bottom: 10px;
}
