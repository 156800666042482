@import url("https://fonts.googleapis.com/css2?family=Unbounded&family=Pacifico&family=Yatra+One&display=swap");

.festival-header {
  font-family: "Yatra One";
  background: -webkit-linear-gradient(rgb(245, 141, 37), rgb(212, 4, 4));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card {
  box-shadow: 0 3px 8px 0 darkorange;
}

.festival-date {
  font-family: "Unbounded";
}

.festival-name {
  font-family: "Unbounded";
}
