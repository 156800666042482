.signup-alert {
    border: none;
    padding: 0px;
    position: relative;
    border-radius: 5px;
}
.share-body {
    border-top: 1px #ddd dashed;
    margin: 15px 0;
    padding: 30px 15px 15px 15px;
    text-align: center;
}
.social-logo-detail span {
    background: #adb0b6;
    height: 45px;
    width: 45px;
    display: inline-block;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
    color: #fff;
    padding: 11px 0;
    display: flex;
    justify-content: center;
    margin: 0 8px;
}