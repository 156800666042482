@media (max-width: 767px) {
  .title {
    font-size: 10px;
  }

  .item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    width: 40%;
    margin: 10px;
  }

  .item-name {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .item-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
  }
}
