.article-img-div {
  width: 40%;
  padding: 7px;
  border: 5px solid #f16334;
  border-radius: 5px;
  text-align: center;
}

.card {
  width: 100%;
}

@media (min-width: 576px) {
  .card {
    max-width: 75%;
    margin: auto;
  }
}
