@import "../styles/scss/abstracts/_variables.scss";

.search-title {
  font-size: 18px;
  line-height: 40px;
  float: left;
}
.s-r {
  color: $neutral-grey-3;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
}
.close-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.search-div {
  padding-top: 75px;
  z-index: 123;
  width: 100%;
  padding-bottom: 10px;
  height: 100px;
  box-sizing: border-box;
  background: $white;
  box-shadow: 0 2px 0 $pattens_blue;
}
.sd-close-btn {
  font-size: 12px;
  padding: 5px;
  width: 27px;
  height: 27px;
  margin-top: 3px;
  line-height: 15px;
  background: $white;
  border: 1px solid lightgrey;
}
.no_result {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
.drawerbackdrop {
  display: none;
}

.overlays {
  height: -webkit-calc(103vh - 0%);
  height: calc(103vh - 0%);
  position: absolute;
  width: 31rem;
  z-index: 1024;
  top: 70px;
  background-color: $white;
  overflow: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  right: 0;

  @media (max-width: 767px) {
    width: 100%;
    padding: 25px;
  }
}

.detail {
  font-size: 13px;
  font-weight: 500;
  color: $neutral-grey-8;
  .Name {
    font-size: 15px;
    color: $Black;
  }
  span {
    font-size: 12px;
    // margin-left: 11px;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $platinum;
  color: $Black;
  &:hover {
    background-color: $platinum;
    color: $Black;
  }
}
.search-header {
  margin: 11px;
  span {
    font-size: 22px;
    font-weight: 600;
  }
  svg {
    width: 25px;
    height: 25px;
  }
}

.card-body {
  width: 100% !important;
}
