// @media (max-width: 767px) {
//   .title {
//     font-size: 10px;
//   }

//   .item-container {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//   }

//   .card {
//     width: 40%;
//     margin: 10px;
//   }

//   .item-name {
//     font-size: 14px;
//   }
// }

// .newlyadded-item-container {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
// }

.card {
  width: 180px;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
