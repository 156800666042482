@import url("https://fonts.googleapis.com/css2?family=Laila&family=Metamorphous&family=Pacifico&family=Yatra+One&display=swap");

.container {
  margin-top: 25px;
}

label {
  font-family: "Laila";
}

.custom-col {
  margin-bottom: 20px !important;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  backdrop-filter: blur(6px); /* Adjust the blur effect as needed */
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
