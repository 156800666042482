/* @media (max-width: 1200px) {
  .todaydeals-item-container {
    justify-content: center;

    .card {
      width: calc(48% - 20px);
    }
  }
}

@media (max-width: 767px) {
  .todaydeals-item-container {
    flex-wrap: wrap;
    justify-content: space-evenly;

    .card {
      width: calc(48% - 20px);
    }
  }
}

 */

/* .title {
  font-size: 35px;
  font-family: montserrat-font;
  font-weight: 900;
  text-align: center;
} */

/* @media (max-width: 767px) {
  .title {
    font-size: 20px;
  }

  .todaydeals-item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    width: 48%; 
    margin: 5px;
    cursor: pointer;
  }

  .item-name {
    font-size: 14px;
  }
} */

/* custom-styles.css */
.custom-card-col {
  flex: 0 0 100%; /* Disable flex-grow to force 1 card per row on smaller screens */
  max-width: 160px; /* Set the maximum width for smaller screens */
}

@media (min-width: 576px) {
  .row {
    flex-direction: row;
  }
}

@media (min-width: 360px) {
  .row {
    flex-direction: row;
  }
}
