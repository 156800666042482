
:root {
    --primary: #f16334;
    // --warn: purple;
  }
  
$white: #ffffff !default;
$red: var(--primary) !default;
$red-1: #dc3545 !default;
$Black: #000 !default;
$neutral-grey-1: #666A73 !default;
$neutral-grey-2:#adb0b6 !default;
$neutral-grey-3: #333333 !default;
$neutral-grey-4: #777777 !default;
$neutral-grey-5: #555 !default;
$neutral-grey-6: #eeeeee !default;
$neutral-grey-7: #aaaaaa !default;
$neutral-grey-8: #666666 !default;
$neutral-grey-9: #282C35 !default;
$neutral-grey-0: #80808017 !default;
$neutral-black: #212529 !default;
$neutral-blue: #41687e !default;
$neutral-see-blue: #0278b8 !default;
$green: #00ab44 !default;
$light-pink: #fec7b4 !default;
$platinum :#e7e7e7 !default;
$light-silver: #e1e1e1 !default;
$Cyan-Blue: #212529 !default;
$light-cyan-blue: #F4F5F6 !default;
$blue: #007bff !default;
$pattens_blue: #dce1e4 !default;
$pale_blue: #dee2e6 !default; 
$light-grey: #c8c8c8 !default;
$yellow-green : #1cd51cdb !default;
$dark-pink : rgb(225, 64, 91) !default;
$ligh-sky-blue: rgba(13, 110, 253, 0.9490196078);
$White-Smoke : #F8F8FA !default;
$white-smoke-1: #efefef !default;
$white-smoke-2: #f8f8fa !default;
$gray87: #dedede !default;
$light-sky-blue-2 : #c4e0f6 !default;
$gray88: #8080804a !default;
$dodger-blue : #008fff !default;
$light-pink-1: #fec7b4 !default;
$alice-blue: #eceff1 !default;
$wisp-pink: #f8e9e6 !default;
$cyan-blue: #2196F3 !default;

