@import "../../styles/scss/abstracts/variables";

.attachment{
.card_attachment {
    width: 13rem;
    margin-left: 15px;
    height: 14rem;
    margin-bottom: 15px;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.file {
    width: 13rem;
    height: 14rem;
    background: $neutral-grey-0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.card {
    border: unset;
    border-color: unset;
}
.grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto;
    background-color: $cyan-blue;
    padding: 10px;
  }
  
}


