@import "../../../styles/scss/abstracts/_variables.scss";
.main-menu {
  .dropdown-toggle {
    // height: 50px;
    // width: 50px;
    // background: $white;
    // border-radius: 80%;
    // border: 5px solid #aaa8a8de !important;
    &::after {
      content: none;
    }
    img {
      position: relative;
      right: 10px;
      bottom: 4px;
      border: 4px solid #c8c8c8;
    }
  }
  .dropdown-toggle {
    &:active {
      color: unset;
      background-color: unset;
      border-color: #aaa8a8de !important;
    }
    &:focus {
      color: unset;
      background-color: unset;
      border-color: unset;
      box-shadow: none !important;
    }
    &:hover {
      color: unset;
      background-color: unset;
      border-color: #aaa8a8de !important;
      background: $white;
    }
  }

  .dropdown-menu {
    position: absolute;
    background: #f8f9fa;
    inset: 0px auto auto 0px;
    transform: translate(-183px, 57px) !important;
    width: 246px;
    border-radius: unset !important;
    padding: unset;
    .dropdown-item {
      padding: unset !important;
      height: 47px;
      display: flex;
      align-items: center;
      padding-left: 16px !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #878585;
      border-bottom: 1px solid #8785852e;
    }
    .dropdown-item:hover {
      color: $white !important;
      background-color: $red !important;
    }
  }
}

.action {
  position: absolute;
  right: 0;
  .show.dropdown {
    .dropdown-toggle::after {
      transform: rotate(-45deg);
      top: 7px;
    }
  }
  .dropdown {
    box-shadow: none;
  }
  .dropdown-toggle {
    background-color: unset;
    color: #666666;
    border: unset;
  }
  .dropdown-toggle::after {
    color: #666666;
    position: relative;
    top: 1px;
    left: 0px;
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-right: 2px solid #666666;
    border-top: 2px solid #666666;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1em;
    border-left: unset;
  }
  .dropdown-menu {
    border: none;
    border-radius: unset;
    padding: 0 !important;
    transform: translate(-9px, 49px) !important;
    min-width: 150px !important;
    .dropdown-item {
      padding: unset !important;
      height: 33px;
      display: flex;
      align-items: center;
      padding-left: 6px !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #878585;
    }
    .dropdown-item:hover {
      color: $white !important;
      background-color: $red !important;
    }
  }
  .btn:active:focus {
    box-shadow: none;
  }
}

.border-none {
  border: none !important;
}
.actionside {
  top: 5px;
  .dropdown-menu {
    border-radius: unset;
    padding: 0 !important;
    transform: translate(-4px, 42px) !important;
    min-width: 76px !important;
    .dropdown-item {
      border: 1px solid #ced4da;
      padding-left: 0px !important;
    }
    .dropdown-item:hover {
      color: $white !important;
      background-color: $red !important;
    }
  }
  .btn:active:focus {
    box-shadow: none;
  }
}

.events {
  .btn-primary:hover {
    background: #f16334;
    box-shadow: none;
    color: white;
  }
  .btn:focus {
    background: #f16334;
    box-shadow: none;
    color: white;
  }
  .dropdown:first-child {
    .dropdown-toggle {
      margin-right: 74px;
    }
  }
  .dropdown-toggle.btn.btn-primary {
    display: flex;
    align-items: center;
    border-radius: unset;
    width: 315px;
    justify-content: space-between;
    padding: 5px 10px;
    &:hover:focus:active {
      background: #f16334;
      border-width: 0;
      box-shadow: none;
      border-radius: unset;
      color: white;
    }
  }
  .dropdown-toggle::after {
    color: #fff;
    content: "";
    width: 9px;
    height: 9px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    margin-right: 0.5em;
    border-left: unset;
    background-image: none !important;
    transition: 0.2s ease-in-out;
    transform: rotate(134deg) !important;
    margin-top: 0px;
  }
  .show.dropdown {
    .dropdown-toggle::after {
      transform: rotate(-45deg) !important;
      margin-top: 6px;
    }
  }
  .dropdown-menu {
    width: 315px;
    border-radius: unset;
    border: none;
    border-bottom: 1px solid #bebebe;
  }
  .dropdown-item {
    color: #f16334;
    &:hover,
    &:focus,
    &:active {
      background-color: unset;
      color: #f16334;
    }
  }
}
