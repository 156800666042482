.swiper-img {
  // height: 380px;
  // width: 100%;
  object-fit: fill;
  cursor: pointer;
}

// @media screen and (max-width: 320px) {
//   .swiper-img {
//     width: 100%;
//     height: auto;
//   }
// }
