@import "../../../styles/scss/abstracts/_variables.scss";
.edit_btn {
  margin-right: 10px;
  background: $red;
  border: none;
  color: $white;
  height: 38px;
  width: 60px;
  border-radius: 5px;
}
.add_new_event {
  background: $blue;
  color: $white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 140px;
}

.add_new_event:hover{
  background: rgb(232, 82, 82);
  color: $white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 140px;
}
.delete_btn {
  background: $dark-pink;
  color: $white;
  border: none;
  height: 38px;
  width: 80px;
  border-radius: 5px;
}
.approved_btn {
  margin-top: 5px;
  color: $white;
  background: $yellow-green;
  border: none;
  height: 30px;
  width: 100px;
  border-radius: 20px;
  font-size: 12px;
  cursor: unset;
}

.pending_btn {
  margin-top: 5px;
  color: $white;
  background: $red;
  border: none;
  height: 30px;
  width: 100px;
  border-radius: 20px;
  font-size: 12px;
}
.ver_center {
  vertical-align: center !important;
}

