.signup-title h1{
    font-family: 'bentonsansmedium';
    font-size: 35px;
}
.signup-title p{
    padding-top: 10px;
    font-size: 14px;
}
.text-center {
    text-align: center!important;
}
.sign-in-form {
    width: 35%;
    margin: 0 auto;
    min-height: 451px;
}
.get_started {
    margin-top: 10px;
    width: 100%;
    padding: 8px;
    color: #F16334;
    background-color: transparent;
    font-family: 'bentonsansmedium';
    border: 2px solid #F16334;
    padding: 10px 12px;
    font-size: 14px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    font-weight: 500;
    &:hover {
       background-color: red;
       color: white;
      }
}
.forget_password {
    color: red;
    text-decoration: none;
    float: left;
    cursor: pointer;
}
.contact-form {
    padding: 50px 0;
}
.email {
        padding: 10px 0;
        width: 100%;
        font-size: 14px;
        margin: 20px 0;
        border: none;
        color: #666;
        background-color: white;
        outline: none;
        border-bottom: 2px solid #F16334;
        border-radius: 0px;
    }
.form-group {
    margin-bottom: 1rem;
}
.new-user {
    text-transform: capitalize!important;
    color: red;
    text-decoration: none;
    float: right;
    cursor: pointer;
}
.error {
    color: red;
    float: left;
}

 