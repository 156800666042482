@import "../../styles/scss/abstracts/_variables.scss";

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 0px;
}

.visible {
  /* display: flex; */
  top: 0 !important;
  transition: top 0.4s ease-out;
}

.hidden {
  /* display: none; */
  top: -80px !important;
  transition: top 0.4s ease-out;
}

.Main-header {
  .logo {
    cursor: pointer;
  }
  .logo_img {
    display: inline-block;
    height: 60px;
  }
  .name_logo {
    color: #f16334;
    font-weight: 700;
    font-size: x-large;
  }
  .header {
    background: $white;
  }
  .header-mains-menu {
    height: 70px;
    margin: 0px;
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  }
  .header_scroll {
    position: fixed;
    width: 100%;
    display: block;
    transition: top 0.3s;
    background-color: $white;
  }
  .common {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $Cyan-Blue;
    text-align: left;
  }
  .search {
    position: relative;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
  }

  .searchMain {
    position: relative;
  }
  .header-search {
    width: 230px;
  }

  .search-box {
    background-color: $white;
    border-radius: 0px;
    position: relative;
    width: 100%;
    display: inline-block;
    margin: 0;
    line-height: 30px;
    height: auto;
    border-radius: 25px;
    color: $neutral-grey-8;
    opacity: 1;
    &:focus {
      box-shadow: none;
    }
  }
  .search-box::placeholder {
    color: $neutral-grey-8;
    //   width : 100px;
    // overflow:hidden;
    // display:inline-block;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .searchicon {
    color: $neutral-grey-8;
    font-size: 18px;
    line-height: 20px;
  }
  .search-button {
    display: inline-block;
    border: none;
    background: transparent;
    border-radius: 0;
    top: 10px;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 14px;
  }
  .header-menu {
    text-align: right;
  }
  .show-menu {
    color: $neutral-grey-3;
    text-align: right;
    padding: 10px 0;
    font-size: 20px;
    display: none;
  }

  .side-menu {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    display: inline-block;
  }
  .browse-event {
    color: black;
    text-decoration: none;
    padding: 20px 12px 18px 12px;
    display: flex;
    justify-content: center;
  }
  .browse-event:hover {
    color: $red;
  }
  .menu-li {
    display: inline-block;
    position: relative;
  }
  .signin-btn {
    background: $red;
    padding: 7px 10px 8px 10px !important;
    border-radius: 5px;
    color: $white !important;
    text-decoration: none;
    cursor: pointer;
  }

  .backdrop {
    opacity: 0 !important;
    top: 70px !important;
    height: unset !important;
    width: unset !important;
  }
  .drawer {
    top: 70px !important;
    border: none;
    min-width: 320px;
    width: 346px !important;
    border-left: none !important;
  }
  .side-menu {
    background: $white;
    display: block;
    height: 100%;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    .signin-btn {
      background: $red;
      padding: 7px 10px 8px 10px !important;
      border-radius: 5px;
      color: $white !important;
    }
  }
  .side-menu li a {
    padding: 10px;
    display: block;
  }
  .side-menu li {
    padding: 0;
    margin-bottom: 1px;
    border: none;
    width: 100%;
    border-top: 1px solid $platinum;
  }
  .side-menu li:first-child {
    border-top: none;
  }
  .side-menu li:nth-child(2) {
    border-top: none;
  }
  .side-menu li:nth-child(3) {
    border-top: none;
  }
  .side-menu li {
    display: inline-block;
    position: relative;
  }
  .side-menu li a {
    color: $neutral-grey-8;
    text-decoration: none;
    padding: 20px 17px 18px 15px;
    display: flex;
    justify-content: center;
  }
  .side-menu li a.user-link {
    padding: 6px 15px;
  }

  .side-menu li a:hover {
    color: $red;
  }
  .vertical {
    border-left: 1px solid darkgray;
    height: 25px;
    position: relative;
    right: 115px;
  }
  .loc {
    text-transform: capitalize;
  }
  .location {
    position: relative;
    left: 37px;
    z-index: 12;
    top: 9px;
    svg {
      height: 22px;
      width: 22px;
      color: $red;
    }
  }
  .btn:focus {
    box-shadow: none;
  }
  .selectbox {
    margin-right: 58px;
  }
  .selectbox__menu {
    z-index: 43;
  }
  .selectbox__control {
    border-radius: 25px !important;
    border: 1px solid #ced4da !important;
    min-height: 44px !important;
  }
  .selectbox__option {
    background-color: unset !important;
  }
  .selectbox__control--is-focused {
    border-color: $red !important;
    box-shadow: none !important;
    color: $white;
  }
  .selectbox__option:hover {
    background-color: $red !important;
    color: $white;
  }
  .selectbox__option--is-selected {
    color: $white;
    background-color: $red !important;
  }
  .selectbox__indicator-separator {
    display: none;
  }
  .langbox {
    position: absolute;
    right: 2px;
  }
  .profile-image {
    padding-bottom: 35px !important;
    padding-top: 35px !important;
    margin-left: 13px;
    width: unset !important;
  }
  .name {
    color: $Black;
    span {
      color: $neutral-grey-8;
    }
  }
  .loggedIn {
    height: 59px;
    width: 59px;
    background: $white;
    border-radius: 80%;
    border: 5px solid #aaa8a8de !important;
    &::after {
      content: none;
    }
    img {
      width: 50px;
    }
  }
}

@media only screen and (max-width: 999px) {
  .Main-header {
    .langbox {
      right: 70px;
    }
    .common {
      justify-content: flex-start !important;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .Main-header {
    .langbox {
      right: 70px;
    }
    .common {
      justify-content: flex-start !important;
    }
  }
}
