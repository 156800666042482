.suggestion-button {
  height: 7.32vw;
  font-size: 2.2vw;
  font-weight: 700;
  font-family: "montserrat";
}
.suggestion-clicked {
  color: #e28e00;
  background: #483f50;
  border: 2px solid #483f50;
}
.suggestion-not-clicked {
  color: #483f50;
  background-color: white;
  border: 1px solid #483f50;
}
