@import url("https://fonts.googleapis.com/css2?family=Unbounded&family=Pacifico&family=Yatra+One&display=swap");

.month-name {
  font-family: "Unbounded";
  color: chocolate;
  text-align: center;
}

.holiday-header {
  font-family: "Yatra One";
  background: -webkit-linear-gradient(rgb(245, 141, 37), rgb(212, 4, 4));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
