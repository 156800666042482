@import url("https://fonts.googleapis.com/css2?family=Laila&family=Metamorphous&family=Pacifico&family=Yatra+One&display=swap");

.heading-div,
.care-heading,
.product-heading {
  font-family: "Laila";
  font-weight: 800;
}

ul {
  color: black;
}

.product-details-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .detail {
    text-align: left;

    p {
      word-wrap: break-word;
    }
  }

  // li {
  //   background: url("../../img/logo/EN.jpg") no-repeat left center;
  //   background-size: 26px; /* Adjust the size as needed */
  //   padding-left: 20px; /* Add some padding to separate the text from the image */
  // }
}

// .product-details-list {
//   list-style: none;
//   padding: 0;
//   margin: 0;

//   .detail {
//     text-align: left;

//     p {
//       word-wrap: break-word;
//     }
//   }

//   li {
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px; /* Adjust the spacing between list items if needed */

//     &:before {
//       content: url("../../img/logo/EN.jpg");
//       margin-right: 10px; /* Adjust the spacing between the image and text */
//       width: 16px; /* Set the desired width for the image */
//       height: 16px; /* Set the desired height for the image */
//       object-fit: contain; /* Ensure the image is contained within the specified dimensions */
//     }

//     strong {
//       font-weight: 700; /* Optionally adjust the font-weight */
//     }
//   }
// }

// .product-details-list {
//   list-style-type: none;
//   padding: 0;
//   margin: 0;

//   li {
//     text-align: left;
//     display: flex;
//     flex-direction: column;

//     align-items: center;

//     &::before {
//       content: "";
//       background: url("../../img/logo/EN.jpg") no-repeat;
//       background-size: contain; /* Ensure the image is contained within the pseudo-element */
//       width: 25px; /* Adjust the width of the image */
//       height: 25px; /* Adjust the height of the image */
//       margin-right: 5px; /* Adjust the spacing between icon and text */
//     }

//     strong {
//       font-weight: 700; /* Optionally adjust the font-weight */
//     }

//     p {
//       word-wrap: break-word;
//     }
//   }
// }

// .product-details-list {
//   list-style-type: none;
//   padding: 0;
//   margin: 0;

//   li {
//     text-align: left;
//     display: flex; /* Align icon and text in a row */
//     flex-direction: column;

//     &::before {
//       content: "";
//       background: url("../../img/logo/EN.jpg") no-repeat;
//       background-size: contain;
//       width: 20px;
//       height: 20px;
//       margin-right: 8px;
//     }

//     strong {
//       font-weight: 700; /* Optionally adjust the font-weight */
//     }

//     p {
//       word-wrap: break-word;
//       margin-left: 8px; /* Adjust the spacing between strong and p tag content */
//     }
//   }
// }
