@import "../../styles/scss/abstracts/_variables.scss";

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.content-title {
  margin: 25px 0px;
}
.padding {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
}
// .card-design {
//   display: flex;
//   flex-wrap: wrap;
// }
.see-more {
  border: 1px solid $neutral-blue;
  border-radius: 4px;
  background: transparent;
  color: $neutral-blue;
  text-decoration: none !important;
  padding: 10px 20px;
  outline: none !important;
}
.hover:hover {
  transition: all 0.3s;
  transform: translateY(-20px);
}
.section-bg {
  position: relative;
  padding-top: 25px;
  padding-bottom: 40px;
  // background: radial-gradient(at top right, #ec72472b 60%, #f8f8fa 80px);
  background-attachment: fixed;
}
.suggested {
  font-size: large;
  margin-top: 16px;
}
.home-button {
  min-height: 14.64vw;
  width: 22.4vw;
  border-radius: 1.46vw;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 1.47vw;
}
.home-horoscope-button {
  background: url("../../img/home/horoscope-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home-panchang-button {
  background: url("../../img/home/panchang-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home-kundli-button {
  background: url("../../img/home/kundli-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home-event-button {
  background: url("../../img/home/event-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-horoscope-sign-clicked {
  img {
    height: 8.78vw;
    width: 8.78vw;
    background: linear-gradient(#ff6129, #00b876, #ffa900, #4f5bff);
    border-radius: 50%;
  }
  p {
    opacity: 100%;
  }
}
.home-horoscope-sign-not-clicked {
  img {
    height: 8.78vw;
    width: 8.78vw;
    background: white;
    border-radius: 50%;
    opacity: 40%;
  }
  p {
    opacity: 50%;
  }
}

.home-horoscope-details {
  background: url("../../img/home/horoscope-details-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 47.15vw;
  padding: 20px;
  border-radius: 22px;
}

.home-horoscope-details-button {
  height: 3.66vw;
  width: 12.45vw;
  background: #eb7625;
  font-family: "montserrat";
  font-size: 1.47vw;
  font-weight: 700;
  border-radius: 0.44vw;
  border: 0;
}
.horoscope-details-accordion {
  background: linear-gradient(to bottom, #feeed6, #ffc061) !important;
  margin-bottom: 0.73vw;
  border-radius: 0.73vw;
  border: 0 !important;
  font-family: "montserrat";
  font-weight: 700;

  .accordion-button {
    box-shadow: none !important;
    background: #feeed6 !important;
    color: black !important;
    font-weight: 700;
  }
  .accordion-header {
    font-size: 10vw !important;
  }
  .home-prediction-body {
    height: 250px;
    overflow-y: scroll;
  }
}
.events-section-header {
  font-size: 2.55vw;
  font-family: "Playfair Display", serif;
  margin: 5vw 0 3.66vw 0;
  text-align: center;
}
.events-div {
  min-height: 36.603vw;
  border-radius: 1.47vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .home-horoscope-sign-clicked {
    img {
      height: 50px;
      width: 50px;
    }
  }
  .home-horoscope-sign-not-clicked {
    img {
      height: 50px;
      width: 50px;
    }
  }
  .horoscope-details-accordion {
    .accordion-button {
      font-size: 10px;
    }
    .accordion-body {
      font-size: 10px;
    }
  }
}
