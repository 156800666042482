@import "../../styles/scss/abstracts/_variables.scss";

.manage_events {
.user-events {
    font-family: "bentonsansregular","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    background: $White-Smoke;
}
.margin-top {
    margin-top: 50px;
}

.user-events .event-sidebar ul {
    padding: 10px;
    background: $white;
    border: none;
}

.nav-link {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        text-decoration: none;
        color: $Black;
        display: flex;
}
.nav-tabs .nav-link.active {
    color: $white !important; 
    background-color: $red !important;
    border-color: var(--bs-nav-tabs-link-active-border-color);
    span {
        color: $white !important;
        font-weight: 800;
    }
}
.nav .nav-link {
    border-bottom: 1px solid $white-smoke-1;
    border-top: none;
    border-right: none;
    border-left: none;
}
.label {
    background: $gray87;
    font-size: 11px;
    padding: 0 5px;
    width: 30px;
    text-align: center;
    border-radius: 3px;
    border: 0px solid $red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $neutral-grey-8;
}
.events {
    font-size: 13px;
    line-height: 20px;
    display: block;
    color: $neutral-grey-8;
    letter-spacing: 1px;
    clear: both;
}
.eventbox {
    background: $white-smoke-2;
    border: 1px solid $white-smoke-1;
    padding: 8px;
    margin-bottom: 5px;
    position: relative;
}
.eventbox h3.event-title {
    color: $red;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0 0 0 0;
    width: calc(100% - 75px);
}
.eventbox-main .eventbox-events {
    padding: 5px;
    background: $white;
}
}


