Nav > Nav.Item {
  border: none !important;
  outline: none !important;
}

.custom-link {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: darkred;
  }
}

.mobile-menu {
  display: none;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;

  .custom-link {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    transition: color 0.3s ease;

    &:hover {
      color: #e44d26;
    }
  }

  &.show {
    display: -webkit-flex;
    display: flex;
  }

  .search-box {
    width: 250px;
  }
}

.logo_img {
  width: auto;
  height: 78px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.5s,
    backface-visibility 0.3s;
  border-radius: 5px;
  border: 1px solid transparent;

  &:hover,
  &:active {
    transform: translateY(4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    background-color: #e44d26;
    border: 1px solid #e44d26;
  }

  &:hover {
    backface-visibility: hidden;
  }
}

// .selectbox__menu {
//   max-height: 150px;
//   overflow-y: auto;
// }

// .selectbox__menu {
//   max-height: 175px;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
//   scroll-behavior: smooth;
//   border-radius: 8px;

//   &::-webkit-scrollbar {
//     width: 4px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #888;
//     border-radius: 4px;
//     transition: background-color 0.3s ease;
//   }
// }

// .selectbox__option {
//   font-size: 14px;
//   padding: 8px 16px;
//   white-space: nowrap; // Preventing text from wrapping
// }

// .selectbox__menu-list {
//   overflow-y: hidden !important;
// }

// .selectbox__option {
//   font-size: 14px;
// }

@media (min-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .example {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media (max-width: 761px) {
  .mobile-menu {
    top: auto;
    bottom: 0;
  }
}

@media (max-width: 746px) {
  .mobile-menu {
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 746px) and (max-width: 1280px) {
  .mobile-menu {
    top: 0;
    bottom: auto;
  }
}

// .mobile-menu {
//   display: none;
// }

// .mobile-menu.expanded {
//   display: block;
// }

// @media (max-width: 767px) {
//   .mobile-menu {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 10px;
//   }

//   .mobile-menu.open {
//     display: flex !important;
//   }
// }

// Navbar .nav-btn {
//   padding: 5px;
//   background: transparent;
//   border: none;
//   outline: none;
//   visibility: hidden;
//   opacity: 0;
//   font-size: 1.8rem;
// }

// @media only screen and (max-width: 1024px) {
//   Navbar .nav-btn {
//     visibility: visible;
//     opacity: 1;
//   }

//   Navbar Nav {
//     position: fixed;
//     top: -100vh;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 1.5rem;
//     transition: 1s;
//   }

//   Navbar .responsive_nav {
//     transform: translateY(100vh);
//   }

//   Nav .nav-close-btn {
//     position: absolute;
//     top: 6rem;
//     right: 2rem;
//   }

//   Nav Nav.Item {
//     font-size: 1.5rem;
//   }
// }

// Navbar .nav-btn {
//   padding: 5px;
//   background: transparent;
//   border: none;
//   outline: none;
//   visibility: hidden;
//   opacity: 0;
//   font-size: 1.8rem;
// }

// @media only screen and (max-width: 1024px) {
//   Navbar .nav-btn {
//     visibility: visible;
//     opacity: 1;
//   }

//   Navbar Nav {
//     position: fixed;
//     top: -100vh;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 1.5rem;
//     transition: 1s;
//   }

//   Navbar .responsive_nav {
//     transform: translateY(100vh);
//   }

//   Navbar .nav-close-btn {
//     position: absolute;
//     top: 6rem;
//     right: 2rem;
//   }

//   Navbar .custom-link {
//     font-size: 1.5rem;
//   }
// }
